<template>
  <div></div>
</template>

<script>
  export default {
    name: 'Day'
  }
</script>

<style scoped>

</style>
